import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import Cookies from 'js-cookie'
import Axios from '@/utils/axios'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import './assets/styles/index.scss'

import _ from 'lodash'
Vue.prototype._ = _

import echarts from 'echarts' // 引入echarts
Vue.prototype.$echarts = echarts // 引入组件（将echarts注册为全局）

Vue.prototype.$cookies = Cookies
Vue.prototype.$axios = Axios
Vue.config.productionTip = false


// import VConsole from 'vconsole';
// var vConsole = new VConsole();

new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate() {
    (function () {
      var baseFontSize = 100;
      var baseWidth = 750;

      var set = function () {
        var clientWidth = document.documentElement.clientWidth || window.innerWidth;

        var rem = 100;
        if (clientWidth != baseWidth) {
          rem = Math.floor(clientWidth / baseWidth * baseFontSize);
        }

        document.querySelector('html').style.fontSize = rem + 'px';
      }
      set();

      window.addEventListener('resize', set);
    }());
  }
}).$mount('#app')
