<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  created () {
  // 在页面加载时读取sessionStorage
  // if (sessionStorage.getItem('store')) {
  //  this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
  // }
  // // 在页面刷新时将store保存到sessionStorage里
  // window.addEventListener('beforeunload', () => {
  //  sessionStorage.setItem('store', JSON.stringify(this.$store.state))
  // })
 },
 destroyed() {
  //   window.addEventListener('beforeunload', () => {
  //  sessionStorage.setItem('store', JSON.stringify(this.$store.state))
  // })
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  font-size: 0.26rem;
  min-height: 100vh;
  background: #f1f1f1;
}
.el-message-box__wrapper .el-message-box{
  width: 90% ;
}

  .el-scrollbar {
    .el-scrollbar__bar {
        opacity: 1 !important;
    }
  }
</style>
