import axios from 'axios'


axios.create({
    baseURL: process.env.BASE_API, // api 的 base_url
    timeout: 100000 // request timeout
})

export default (method, url, data) => {
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: url,
            timeout: 100000, // 十秒超时
            ...data
        }).then(response => {
            resolve(response)
        }).catch(err => {
           console.log('err--',err.response);
            reject(err.response)
        })
    })
}