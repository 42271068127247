import CryptoJS from 'crypto-js'
import LRU from 'lru-cache'

export const getParamsUrl = (params) => {
  let url = '?';
  for (let key in params) {
    if (params[key] != undefined) {
      url += `${key}=${params[key]}&`
    }
  }
  return url.substr(0, url.length - 1);
}

// 加密
export const encrypt = (payload) => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(payload),
    "yzy"
  ).toString();
  return ciphertext;
}

// 解密
export const decrypt = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, 'yzy')
  const token = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return token;
}

// 30秒缓存
export const cache = new LRU({
  max: 1000,
  maxAge: 1000 * 30
})


//给数据添加是否选中属性
export const dealQsData = (data) => {
  if (data && data.length > 0) {
    data.forEach(item => {
      if (item.choices && item.choices.length > 0) {
        item.localStoreAnswer = [];
        item.choices.forEach(c => {
          c.is_select = false;
          c.disabled = false;
        });
      } else {
        if (item.sub_questions && item.sub_questions.length > 0) {
          item.sub_questions.forEach(c => {
            c.is_select = false;
          });
          dealQsData(item.sub_questions);
        }
      }
    });

    return data;
  } else {
    return [];
  }
}
// 对象转URL参数
export const getParams = (params) => {
  let paramStr = '';
  Object.keys(params)
    .forEach((item) => {
      if (paramStr === '') {
        paramStr = `${item}=${params[item]}`;
      } else {
        paramStr = `${paramStr}&${item}=${params[item]}`;
      }
    });
  console.log(paramStr);
  return paramStr;
}
export const getUrlAllParams = (url) => {
  // 获取url中全部参数的对象
  // 解决乱码问题
  var url = decodeURI(url)
  var res = {}
  var url_data = _.split(url, '?').length > 1 ? _.split(url, '?')[1] : null;
  if (!url_data) return null
  var params_arr = _.split(url_data, '&')
  _.forEach(params_arr, function (item) {
    var key = _.split(item, '=')[0]
    var value = _.split(item, '=')[1]
    res[key] = value
  });
  return res
}
//表头分数 物/化/生   物化生   史政地
export const getNavData = (data) => {
  let obj = {}
  let list = []
  obj.score = data.real_score?data.real_score:data.predict_score
  if(data.course.indexOf(',')>-1){
    list = data.course.split(',')
    obj.value = list[0].substring(0, 1) + '/' + list[1].substring(0, 1) + '/' + list[2].substring(0, 1)
    let str1=obj.value.indexOf('物')
    let str2=obj.value.indexOf('化')
    let str3=obj.value.indexOf('生')
    let str4=obj.value.indexOf('史')
    let str5=obj.value.indexOf('政')
    let str6=obj.value.indexOf('地')
    let _exist1=(str1>-1)&&(str2>-1)&&(str3>-1)
    let _exist2=(str4>-1)&&(str5>-1)&&(str6>-1)
    console.log(_exist2,666,_exist1);
    if(_exist1){
      obj.value='物/化/生'
    }
    if(_exist2){
      obj.value='史/政/地'
    }
  }else{
    obj.value =data.course
  }
  

  return obj
}