import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import { Toast } from "vant";
Vue.use(Toast);
import store from "./store";

const Layout = () => import("./views/layout");
const Login = () => import("./views/login/index");
const Index = () => import("./views/index");
const Signin = () => import("./views/signin/index");
const Signup = () => import("./views/signup/index");
const LoginResult = () => import("./views/loginResult/index");
const FillScore = () => import("./views/fillScore/index");
const SelectCity = () => import("./views/selectCity/index");
const ResetPassword = () => import("./views/resetPassword");

const AssessmentSystemTestHome = () => import("./views/assessmentSystem");
const CareerPlanningTest = () =>
  import("./views/assessmentSystem/careerPlanning");
const LearningStateTest = () =>
  import("./views/assessmentSystem/learningState");
const SelectCourseTest = () => import("./views/assessmentSystem/selectCourse");
const AssessmentSystemTest = () => import("./views/assessmentSystem/test");

const SimulatedFill = () => import("./views/simulatedFill/fillTable/index.vue");

const ChangePici = () => import("./views/changePici");

const MyCenter = () => import("./views/mycenter");
const MyTablesList = () => import("./views/mycenter/myTablesList");
const MyTableDetail = () => import("./views/mycenter/myTablesList/detail");

const MyEvaluations = () => import("./views/mycenter/myEvaluations");

const NewTableMajor = () => import("./views/simulatedFill/newTableMajor");
const NewPreviewTable = () => import("./views/simulatedFill/newPreviewTable");
//模拟填报
const CreateScore = () => import("./views/accounts/createScore");
//找大学
const CollegeList = () => import("./views/colleges/collegeList");
//查专业
const Majors = () => import("./views/majors/homepage");
//看职业
const Job = () => import("./views/job/homepage");

//批次线
const RankSearch = () => import("./views/rankSearch/homepage");
//位次查询
const Pcl = () => import("./views/pcl/homepage");

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "layout",
      component: Layout,
      meta: {
        title: ""
      },
      children: [
        {
          path: "",
          name: "Index",
          component: Index,
          meta: {
            title: "首页",
            showPageNavBar: true,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "login",
          name: "login",
          component: Login,
          meta: {
            title: "",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: false,
          }
        },
        {
          path: "signin",
          name: "Signin",
          component: Signin,
          meta: {
            title: "登录",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: false,
          }
        },
        {
          path: "signup",
          name: "Signup",
          component: Signup,
          meta: {
            title: "注册",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: false,
          }
        },
        {
          path: "loginResult",
          name: "LoginResult",
          component: LoginResult,
          meta: {
            title: "登录",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: false,
          }
        },
        {
          path: "fillScore/:type",
          name: "FillScore",
          component: FillScore,
          meta: {
            title: "填写信息",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "selectCity",
          name: "SelectCity",
          component: SelectCity,
          meta: {
            title: "选择省份",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "resetPassword",
          name: "ResetPassword",
          component: ResetPassword,
          meta: {
            title: "找回密码",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/assessmentSystem/home",
          name: "AssessmentSystemTestHome",
          component: AssessmentSystemTestHome,
          meta: {
            title: "测评",
            showPageNavBar: true,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/assessmentSystem/careerPlanning",
          name: "CareerPlanningTest",
          component: CareerPlanningTest,
          meta: {
            title: "职业测评",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/assessmentSystem/learningState",
          name: "LearningStateTest",
          component: LearningStateTest,
          meta: {
            title: "学习状态测评",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/assessmentSystem/selectCourse",
          name: "SelectCourseTest",
          component: SelectCourseTest,
          meta: {
            title: "选科测评",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/assessmentSystem/test/:type",
          name: "AssessmentSystemTest",
          component: AssessmentSystemTest,
          meta: {
            title: "测评",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/simulatedFill/:key",
          name: "SimulatedFill",
          component: SimulatedFill,
          meta: {
            title: "模拟填报",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/changePici",
          name: "ChangePici",
          component: ChangePici,
          meta: {
            title: "修改批次",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/myCenter",
          name: "MyCenter",
          component: MyCenter,
          meta: {
            title: "个人中心",
            showPageNavBar: true,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/myTablesList",
          name: "MyTablesList",
          component: MyTablesList,
          meta: {
            title: "我的志愿表",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/myTableDetail/:id/:type",
          name: "MyTableDetail",
          component: MyTableDetail,
          meta: {
            title: "我的志愿表",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/myEvaluations/:type",
          name: "MyEvaluations",
          component: MyEvaluations,
          meta: {
            title: "测评结果",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        
        {
          path: "/newTableMajor/:id",
          name: "NewTableMajor",
          component: NewTableMajor,
          meta: {
            title: "专业",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/newPreviewTable",
          name: "NewPreviewTable",
          component: NewPreviewTable,
          meta: {
            title: "预览",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/accounts/createScore",
          name: "CreateScore",
          component: CreateScore,
          meta: {
            title: "模拟填报",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/accounts/chooseBatch",
          name: "chooseBatch",
          component: () => import("./views/accounts/chooseBatch"),
          meta: {
            title: "选择填报批次/段",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/accounts/fill",
          name: "fill",
          component: () => import("./views/accounts/fill"),
          meta: {
            title: "智能填报",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/tzy/details",
          name: "tzyDetail",
          component: () => import("./views/accounts/detail"),
          meta: {
            title: "志愿表",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/accounts/oneFilling",
          name: "oneFilling",
          component: () => import("./views/accounts/oneFilling"),
          meta: {
            title: "一键填报",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/accounts/vip",
          name: "VIP",
          component: () => import("./views/accounts/vip"),
          meta: {
            title: "开通VIP",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/accounts/one-fill",
          name: "personalization",
          component: () => import("./views/accounts/personalization"),
          meta: {
            title: "个性化设置",
            showPageNavBar: false,
            needLogin: true,
            showMenuBox: true,
          }
        },
        {
          path: "/colleges/collegeList",
          name: "collegeList",
          component: () => import("./views/colleges/collegeList"),
          meta: {
            title: "找大学",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/colleges/detail",
          name: "collegeDetail",
          component: () => import("./views/colleges/detail"),
          meta: {
            title: "大学详情",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/colleges/compare",
          name: "Compare",
          component: () => import("./views/colleges/compare"),
          meta: {
            title: "院校对比",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/majors/homepage",
          name: "majors",
          component: Majors,
          meta: {
            title: "查专业",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/majors/detail",
          name: "majorsDetail",
          component: () => import("./views/majors/detail"),
          meta: {
            title: "专业详情",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/job/homepage",
          name: "job",
          component: Job,
          meta: {
            title: "看职业",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/job/detail",
          name: "jobDetail",
          component: () => import("./views/job/detail"),
          meta: {
            title: "职业详情",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/rankSearch/homepage",
          name: "rankSearch",
          component: RankSearch,
          meta: {
            title: "位次查询",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/pcl/homepage",
          name: "pcl",
          component: Pcl,
          meta: {
            title: "批次线",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/search/index",
          name: "search",
          component: () => import("./views/search/index"),
          meta: {
            title: "搜索",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/contact/aboutUs",
          name: "aboutUs",
          component: () => import("./views/contact/aboutUs"),
          meta: {
            title: "关于我们",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
        {
          path: "/contact/contactUs",
          name: "contactUs",
          component: () => import("./views/contact/contactUs"),
          meta: {
            title: "联系我们",
            showPageNavBar: false,
            needLogin: false,
            showMenuBox: true,
          }
        },
      ]
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  Toast.clear();
  const routerPath = to.path;
  if (to.meta.needLogin) {
    if (JSON.parse(localStorage.getItem("userInfo"))) {
      store.dispatch("getUserInfo");
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});
export default router;
