import axios from 'axios'
import Router from '../router'
import {
  decrypt
} from './index'

import Cookies from 'js-cookie'
import {
  Toast
} from 'vant';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.timeout = 100000
const cached = {}

axios.interceptors.request.use(async (config) => {
    if (Cookies.get('yzy-token')) {
        config.headers['Content-Type'] = "application/json"
        config.headers['Authorization'] = 'Bearer ' + decrypt(Cookies.get('yzy-token'))
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  })

axios.interceptors.response.use(res => {
  // console.log('返回结果---', res);
  if (
    res.config.url &&
    typeof res.config.url === 'string' &&
    res.config.url.search('/php') === 0
  ) {
    // 新接口
    if (res.data.ret != 0) {
      Toast(res.data.msg);
      return false;
    } else {
      return res
    }
  } else {
    // 老接口
    if (res.data.errno != 0) {
      Toast(res.data.message);
      return false;
    }else{
      return res
    }
  }
  
}, err => {
 
  if (err && err.response) {
    if (err.response.status === 403) {
      Toast('您暂时没有权限访问')
    } else if (err.response.status === 502) {
      Toast('服务器暂时处于维护阶段，请稍后访问')
    } else if (err.response.status === 401) {
      localStorage.removeItem('userInfo');
      
      Toast('您暂时没有权限访问,请您先登录')
      // 退出到home页
      setTimeout(() => {
        Router.push({
          name: 'login'
        });
      }, 1000);
    } else {
      console.log('暂时无法访问')
    }
  }
  return Promise.reject(err)
})

export default axios