import {
  cache
} from "@/utils";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import request from "@/api/request";

export const state = () => ({
  logo: "",
  userinfo: null,
  histories: [],
  favorates: [],
  totaleCourseCount: 0, // 总课程数
  currentYear: "",
  currenNavMenu: "", // 导航
  currenCenterNav: "1", // 个人中心选中的菜单
  footerLink: [],
  userAgreement: {},
  notify: {
    count: 0,
    contents: []
  },
  currentProvince: "", //当前城市
  currentProvinceInfo: {}, //当前城市对象
  isNew: false, //是否是新高考
  editScoreEdit: null, //编辑成绩
  is_new: null,
  editPlan:[],
  setFill:{},
  editFill:[],
});

export const getters = {
  userInfo: (state) => state.userinfo,
  currentProvince: (state) => state.currentProvince,
  currentProvinceInfo:(state) => state.currentProvinceInfo,
  editPlan: (state) => state.editPlan,
};

export const mutations = {
  SET_USER(state, userinfo) {
    state.userinfo = userinfo;
    console.log("个人信息", state.userinfo);
  },
  UPDATE_USER(state, payload) {
    state.userinfo = Object.assign(state.userinfo, payload);
  },
  UPDATA_PROVINCE(state, payload) {
    state.currentProvince = payload;
  },
  UPDATA_PROVINCE_INFO(state, payload) {
    state.currentProvinceInfo = payload;
  },
  SET_YEAR(state, payload) {
    state.currentYear = payload;
  },
  UPDATE_IS_NEW(state, payload) {
    state.isNew = payload;
  },
  EDIT_(state, payload) {
    state.isNew = payload;
  },
  setPlan(state, payload) {
    state.editPlan = payload;
    sessionStorage.setItem('editPlan',payload)
  },
  setFill(state, payload) {
    state.setFill = payload;
    sessionStorage.setItem('setFill',payload)
  },
  editFill(state, payload) {
    state.editFill = payload;
    sessionStorage.setItem('editFill',payload)
  }
};

export const actions = {
  async getUserInfo({
    commit
  }, data) {
    const [userinfos, yearInfo] = await Promise.all([
      request("GET", "/api/user/info/"),
      // request("GET", "/php/user.info"),
      request("GET", "/api/year/")
    ]);
    // TODO: 设置用户信息
    console.log("个人信息--", userinfos.data.results);
    commit("SET_USER", userinfos.data.results);
    commit("SET_YEAR", yearInfo.data.results.year);
  },

  updateUser({
    commit
  }, data) {
    commit("UPDATE_USER", data);
  },
  setWatchHistory({
    commit
  }, data) {
    commit("SET_HISTORIES", data);
  },
  setLogo({
    commit
  }, data) {
    commit("SET_LOGO", data);
  },
};

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {}
});

export default store;